import React from "react";
import { Navigate } from "react-router-dom";
import AuthVerify from './../common/auth-verify';

const Authentication = ({ children }) => {
    const user = localStorage.getItem('user');
    if (user === null || AuthVerify(user.token) === false) {
        return <Navigate to="/login" />;
    }
    return children;
};

export default Authentication;