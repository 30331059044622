import axios from 'axios';
import $ from 'jquery';

const API_URL = 'api/camera/';

const getAuthorizeHeader = async (username, password) => {
    var result = await axios.get(API_URL + 'GetPasswordDigest/' + password, { headers: { 'Content-Type': 'application/json' } });

    let authHeader = `<s:Header><Security s:mustUnderstand="1" xmlns="http://docs.oasis-open.org/wss/2004/01/oasis-200401-wss-wssecurity-secext-1.0.xsd">`;
    authHeader = authHeader + `<UsernameToken>`;
    authHeader = authHeader + `<Username>${username}</Username>`;
    authHeader = authHeader + `<Password Type="http://docs.oasis-open.org/wss/2004/01/oasis-200401-wss-username-token-profile-1.0#PasswordDigest">${result.data.passwordDigestOut}</Password>`;
    authHeader = authHeader + `<Nonce EncodingType="http://docs.oasis-open.org/wss/2004/01/oasis-200401-wss-soap-message-security-1.0#Base64Binary">${result.data.nonceb64}</Nonce>`;
    authHeader = authHeader + `<Created xmlns="http://docs.oasis-open.org/wss/2004/01/oasis-200401-wss-wssecurity-utility-1.0.xsd">${result.data.created}</Created>`;
    authHeader = authHeader + `</UsernameToken></Security></s:Header>`;
    return authHeader;
}

class OnvifService {

    async goToPreset(username, password, ipAdd, presetId) {

        try {
            let soapMessage = "<?xml version=\"1.0\" encoding=\"utf-8\"?>";
            soapMessage = soapMessage + "<s:Envelope xmlns:s=\"http://www.w3.org/2003/05/soap-envelope\" xmlns:tptz=\"http://www.onvif.org/ver20/ptz/wsdl\">";
            if (username !== null && username?.length > 0) {
                soapMessage = soapMessage + (await getAuthorizeHeader(username, password));
            }
            soapMessage = soapMessage + "<s:Body xmlns:xsi=\"http://www.w3.org/2001/XMLSchema-instance\" xmlns:xsd=\"http://www.w3.org/2001/XMLSchema\"><tptz:GotoPreset>";
            soapMessage = soapMessage + "<tptz:ProfileToken>MediaProfile000</tptz:ProfileToken>";
            soapMessage = soapMessage + `<tptz:PresetToken>${presetId}</tptz:PresetToken>`;
            soapMessage = soapMessage + "</tptz:GotoPreset>";
            soapMessage = soapMessage + "</s:Body>";
            soapMessage = soapMessage + "</s:Envelope>";

            var wsurl = `http://${ipAdd}/onvif/device_service`;
            $.support.cors = true;

            $.ajax({
                type: "POST",
                //headers: { 'Access-Control-Allow-Origin': '*' },
                url: wsurl,
                crossDomain: true,
                processData: false,
                data: soapMessage,
                //success: function (data, status) {
                //},
                //error: function (xhr, status, err) {
                //}
            });
        }
        catch {

        }
    }
}

export default new OnvifService();