import AuthService from "./../../services/auth-service";
import ClientService from "./../../services/client-service";
import { useDispatch } from 'react-redux';
import { setUser } from './../../redux/userSlice';
import { setClient } from './../../redux/clientSlice';

const RefreshRedux = () => {
    const dispatch = useDispatch();
    const onRefresh = async () => {
        dispatch(setUser(await AuthService.refreshUser()));
        dispatch(setClient((await ClientService.get()).data));
    }
    onRefresh();
    return null;
}

export default RefreshRedux;