import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'api/meeting/';

class MeetingService {

    getMeeting() {
        return axios.get(API_URL + 'get', { headers: authHeader() });
    }

    updateMeeting(data) {
        return axios.post(API_URL + "update", JSON.stringify(data),
            { headers: authHeader() });
    }

    addPoint(data) {
        return axios.post(API_URL + "point/add", JSON.stringify(data),
            { headers: authHeader() });
    }

    updatePoint(data) {
        return axios.post(API_URL + "point/update", JSON.stringify(data),
            { headers: authHeader() });
    }

    deletePoint(pId) {
        return axios.delete(API_URL + 'point/delete/' + pId, { headers: authHeader() });
    }

    orderPoints(data) {
        return axios.post(API_URL + "point/order", JSON.stringify(data),
            { headers: authHeader() });
    }

    activatePoint(pId) {
        return axios.get(API_URL + 'point/activate/' + pId, { headers: authHeader() });
    }

    pause(time) {
        return axios.get(API_URL + 'pause/' + time, { headers: authHeader() });
    }

    disablePause() {
        return axios.get(API_URL + 'disablePause', { headers: authHeader() });
    }
}

export default new MeetingService();