import axios from "axios";
import AuthVerify from './../common/auth-verify';
import authHeader from './auth-header';

const API_URL = "api/user/";

class AuthService {
    login(usrEmail, password) {
        return axios
            .post(API_URL + "signin", JSON.stringify({ "email": usrEmail, "password": password }),
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
            .then(response => {
                if (response.status === 200) {
                    console.log(response);
                    localStorage.setItem("user", JSON.stringify(response.data));
                    return response.data;
                }
                else {
                    window.location = "/login";
                }
            });
    }

    refreshUser() {
        return axios
            .get(API_URL + "refresh", { headers: authHeader() })
            .then(response => {
                if (response.status === 200) {
                    if (response.data.length > 0) return null;
                    localStorage.setItem("user", JSON.stringify(response.data));
                    return response.data;
                }
                else {
                    this.logout();
                }
            });
    }

    remind(usrEmail) {
        return axios.post(API_URL + "remindpassword", JSON.stringify({ "email": usrEmail }),
            { headers: { 'Content-Type': 'application/json' } });
    }

    remindConfirmation(pass, rpass, token) {
        return axios.post(API_URL + "remindpasswordconfirmation", JSON.stringify({ "newPassword": pass, "repeatNewPassword": rpass, "token": token }),
            { headers: { 'Content-Type': 'application/json' } });
    }

    logout() {
        localStorage.removeItem("user");
        window.location = "/";
    }

    register(email, password, uname, fname, lname, phone, cname, ctype, nip, street, bnumber, anumber, zipCode, city) {
        return axios.post(API_URL + "signup", JSON.stringify({
            "email": email,
            "password": password,
            "username": uname,
            "firstname": fname,
            "lastname": lname,
            "phone": phone,
            "companyName": cname,
            "companyType": ctype,
            "nip": nip,
            "street": street,
            "buildingNumber": bnumber,
            "apartmentNumber": anumber,
            "zipCode": zipCode,
            "city": city
        }),
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                if (response.status === 200) {
                    //window.location = "/login";
                }
                else {
                    alert("Wystąpił błąd podczas rejestracji. Jeśli błąd się powtarza skontaktuj się z administracją serwisu.");
                }
            });
    }

    getCurrentUser() {
        let user = localStorage.getItem('user');
        return (user === undefined || AuthVerify(user?.token) === false)
            ? null
            : JSON.parse(user);
    }
}

export default new AuthService();