import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import React, { useState, useEffect } from 'react';
import ClientService from "../../../services/client-service";
import UserService from "../../../services/user-service";
import AuthService from "../../../services/auth-service";
import Header from '../Helpers/Header';
import Sidemenu from '../Helpers/Sidemenu';
import { useDispatch, useSelector } from 'react-redux';
import { setClient } from '../../../redux/clientSlice';
import { setUser } from '../../../redux/userSlice';
import { useTranslation } from "react-i18next";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

const Settings = () => {
    const { i18n } = useTranslation();
    const { t } = useTranslation();
    const user = useSelector((state) => state.user.value);
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(true);
    const [clientName, setClientName] = useState("");
    const [userLanguage, setUserLanguage] = useState(i18n.language);
    const [npass, setNpass] = useState("");
    const [n2pass, setN2pass] = useState("");
    const [opass, setOpass] = useState("");
    const [base64, setBase64] = useState('');

    const getClient = async () => {
        var result = await ClientService.get();
        setClientName(result.data.name);
        if (result.data.base64String)
            setBase64("data:image/png;base64," + result.data.base64String);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        let result = await ClientService.update({ name: clientName, base64String: base64 });


        if (result.status === 200) {
            dispatch(setClient((await ClientService.get()).data));
        } else {
            alert('An error occured during client data change: ' + result.data);
        }

        if (user.uiLanguage != userLanguage) {
            let resultLang = await UserService.setLang({ language: userLanguage });

            if (resultLang.status === 200) {
                dispatch(setUser(await AuthService.refreshUser()));
                i18n.changeLanguage(userLanguage);
                //window.location.reload(false);
            }
        }
    }

    useEffect(() => {
        if (loading === true) { getClient(); setLoading(false); }
    });

    const handlePasswordChangeSubmit = async (event) => {
        event.preventDefault();

        let result = await UserService.changePassword({ newPassword: npass, repeatNewPassword: n2pass, oldPassword: opass });

        if (result.status === 200) {
            alert('Password changed successfully.');
        } else {
            alert('An error occured during password change: ' + result.data);
        }

        setNpass("");
        setN2pass("");
        setOpass("");
    }

    const getBase64 = () => {
        var file = document.getElementById("file").files[0];
        if (file === undefined) return;

        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setBase64(reader.result);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    return (
        <div>
            <Header />
            <div className="content">
                <div className="pnazwa">
                    <input type="text" disabled value={t("settings.title")} size="30" />
                </div>

                <div className="boxes">
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <FormControl fullWidth>
                            <InputLabel sx={{ ml: 2 }} variant="standard" htmlFor="uncontrolled-native">
                                {t("settings.language")}
                            </InputLabel>
                            <Select
                                style={{ marginTop: '10px', width: '10%' }}
                                required
                                id="lang"
                                label="language"
                                name="lang"
                                value={userLanguage}
                                onChange={(ex) => setUserLanguage(ex.target.value)}>
                                <MenuItem value="en">English</MenuItem>
                                <MenuItem value="pl">Polish</MenuItem>
                                <MenuItem value="de">Deutsch</MenuItem>
                            </Select>
                        </FormControl>

                        <div className="row" style={{ marginTop: "20px" }}>
                            <div className="col-1">
                                <img style={{ height: 55 }} src={base64 === '' ? "https://placehold.co/600x400" : base64} alt="logo" />
                            </div>
                            <div className="col-5">
                                <Button
                                    variant="contained"
                                    component="label"
                                    sx={{ mt: 0, mb: 2, mr: 2, minWidth: '5%', height: 55, background: "#AA4A44" }}
                                >
                                    {t("board.file")}
                                    <input
                                        id="file"
                                        label="file"
                                        name="file"
                                        accept="image/png, image/gif, image/jpeg"
                                        type="file"
                                        onChange={(e) => { getBase64(); }}
                                        hidden
                                    />
                                </Button></div>
                        </div>

                        <TextField
                            sx={{ mt: 1, width: '96%' }}
                            required
                            id="clientName"
                            label={t("settings.name")}
                            name="clientName"
                            type="text"
                            value={clientName}
                            onChange={(e) => { setClientName(e.target.value); }} />
                        <Button sx={{ mt: 3, mb: 2, width: '200px', background: "#AA4A44" }} type="submit" variant="contained">
                            {t("button.save")}
                        </Button>
                    </Box>

                    <Box component="form" onSubmit={handlePasswordChangeSubmit} noValidate sx={{ mt: 1 }}>
                        <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
                            <div className="row gutters">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <h4 className="mb-3 main-color-text">{t("settings.passwordChange")}</h4>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div className="form-group">
                                        <TextField
                                            required
                                            fullWidth
                                            id="newpass"
                                            label={t("settings.newPassword")}
                                            name="newpass"
                                            type="password"
                                            onChange={(e) => { setNpass(e.target.value); }}
                                        />
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div className="form-group">
                                        <TextField
                                            required
                                            fullWidth
                                            id="rnewpass"
                                            label={t("settings.repeatPassword")}
                                            name="rnewpass"
                                            type="password"
                                            onChange={(e) => setN2pass(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
                                    <div className="form-group">
                                        <TextField
                                            required
                                            fullWidth
                                            id="oldpass"
                                            label={t("settings.oldPassword")}
                                            name="oldpass"
                                            type="password"
                                            onChange={(e) => setOpass(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row gutters mt-3">
                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                                    <div className="text-right">
                                        <Button
                                            style={{ background: "#AA4A44" }}
                                            type="submit"
                                            variant="contained"
                                            sx={{ mt: 3, mb: 2, width: "200px" }}
                                        >
                                            {t("button.save")}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>
                </div>
            </div>
            <Sidemenu page="settings" />
        </div>
    );
}

export default Settings;