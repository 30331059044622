import { faCogs, faFileImage, faHome, faList, faUser, faVideoCamera } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';

const Sidemenu = (props) => {

    let { page } = props;

    const { t } = useTranslation();

    let dashboardClasses = page !== "dashboard" ? "opcja start" : "opcja start selected";
    let pointsClasses = page !== "points" ? "opcja tematy" : "opcja tematy selected";
    let guestsClasses = page !== "guests" ? "opcja goscie" : "opcja goscie selected";
    let boardsClasses = page !== "boards" ? "opcja boards" : "opcja boards selected";
    let camerasClasses = page !== "cameras" ? "opcja kamery" : "opcja kamery selected";
    let settingsClasses = page !== "settings" ? "opcja ustawienia" : "opcja ustawienia selected";

    return (
        <div className="sidebar">
            <div className="logo">
                <img className="logo" src="./logo_esitzung.png" alt="logo" />
            </div>
            <div className="menu">
                <NavLink tag={Link} to="/"><div className={dashboardClasses}><FontAwesomeIcon icon={faHome} /> {t("sidemenu.dashboard")}</div></NavLink>
                <NavLink tag={Link} to="/points"><div className={pointsClasses}><FontAwesomeIcon icon={faList} /> {t("sidemenu.titles")}</div></NavLink>
                <NavLink tag={Link} to="/guests"><div className={guestsClasses}><FontAwesomeIcon icon={faUser} /> {t("sidemenu.guests")}</div></NavLink>
                <NavLink tag={Link} to="/cameras"><div className={camerasClasses}><FontAwesomeIcon icon={faVideoCamera} /> {t("sidemenu.camera")}</div></NavLink>
                <NavLink tag={Link} to="/boards"><div className={boardsClasses}><FontAwesomeIcon icon={faFileImage} /> {t("sidemenu.boards")}</div></NavLink>
                <NavLink tag={Link} to="/settings"> <div className={settingsClasses}><FontAwesomeIcon icon={faCogs} /> {t("sidemenu.settings")}</div></NavLink>
            </div >
        </div>

    );
}

export default Sidemenu;
