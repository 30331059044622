import { faEdit, faRemove, faArrowUp, faArrowLeft, faArrowRight, faArrowDown, faSave, faHome, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import React, { Component } from 'react';
import CameraService from "./../../services/camera-service";
import OnvifService from "./../../services/onvif-service";
import { DeleteConfirmationModal } from './DeleteConfirmationModal';
import VideoJS from './VideoJS'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

const videoJsOptions = {
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [{
        src: 'https://localhost:44444/transmission/00000000-0000-0000-0000-000000000000/index.m3u8',
        type: 'application/x-mpegURL'
    }]
};

const handlePlayerReady = (player) => {
    // You can handle player events here, for example:
    player.on('waiting', () => {
        console.log('player is waiting');
    });

    player.on('dispose', () => {
        console.log('player will dispose');
    });
};

export class EditCameraModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            itemId: '',
            item: { name: "", ipAddress: "", endpoint: "", port: "", login: "", password: "", presets: [] },
            preset: { name: "", token: "" },
            selectedPresetId: '',
            editModalIsOpen: false,
            deleteModalIsOpen: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleOnClose = this.handleOnClose.bind(this);
        this.handleEditClick = this.handleEditClick.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.deletePresetConfirmation = this.deletePresetConfirmation.bind(this);
        this.onDeleteClose = this.onDeleteClose.bind(this);

        this.handleCameraUpClick = this.handleCameraUpClick.bind(this);
        this.handleCameraLeftClick = this.handleCameraLeftClick.bind(this);
        this.handleCameraRightClick = this.handleCameraRightClick.bind(this);
        this.handleCameraSaveClick = this.handleCameraSaveClick.bind(this);
        this.handleCameraHomeClick = this.handleCameraHomeClick.bind(this);
        this.handleCameraDownClick = this.handleCameraDownClick.bind(this);
        this.handleCameraStop = this.handleCameraStop.bind(this);
        this.handleCameraZoomIn = this.handleCameraZoomIn.bind(this);
        this.handleCameraZoomOut = this.handleCameraZoomOut.bind(this);
    }

    async getCamera() {
        let result = await CameraService.get(this.state.itemId);
        if (result.status === 200) {
            this.setState({ item: result.data });
        }
    }

    componentDidUpdate() {
        if (this.props.itemId !== this.state.itemId && this.props.itemId !== null && this.props.itemId !== false && this.props.itemId !== '') {
            this.setState({ itemId: this.props.itemId }, () => { this.getCamera(); });
        }
    }

    async handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        if (data.get('name').length === 0 || data.get('ipAddress').length === 0 || data.get('port').length === 0) {
            alert("Wszystkie pola muszą być wypełnione!")
            return;
        }

        let result = await CameraService.update({ id: this.state.itemId, name: data.get('name'), ipAddress: data.get('ipAddress'), port: data.get('port'), endpoint: "", login: data.get('login'), password: data.get('password') });

        if (result.status === 200) {
            this.props.onConfirmation();
        }
    }

    handleEditClick(cId) {
        this.setState({ editModalIsOpen: true, selectedPresetId: cId });
    }

    handleDeleteClick(cId) {
        this.setState({ deleteModalIsOpen: true, selectedPresetId: cId });
    }


    handleOnClose(event) {
        this.props.onClose();
    }

    async deletePresetConfirmation() {
        var result = await CameraService.deletePreset(this.state.selectedPresetId);

        if (result.status === 200) {
            let arr = (JSON.parse(JSON.stringify(this.state.item.presets))).filter(p => p.id !== this.state.selectedPresetId);
            this.setState({ item: { ...this.state.item, presets: arr } });
        }

        this.setState({ deleteModalIsOpen: false, selectedPresetId: null });
    }

    async onDeleteClose() {
        this.setState({ deleteModalIsOpen: false, selectedPresetId: null });
    }

    async handleCameraUpClick(event) {
        console.log("handleCameraUpClick");
        await CameraService.up(this.state.itemId);
    }

    async handleCameraLeftClick(event) {
        console.log("handleCameraLeftClick");
        await CameraService.left(this.state.itemId);
    }

    async handleCameraHomeClick(event) {
        console.log("handleCameraLeftClick");
        await CameraService.home(this.state.itemId);
    }

    async handleCameraSaveClick(event) {
        if (this.state.preset.name.length === 0 || this.state.preset.token.length === 0) {
            alert("Wszystkie pola muszą być wypełnione!");
            return;
        }
        let camera = JSON.parse(JSON.stringify(this.state.item));
        camera.presets = [];

        var result = await CameraService.addPreset({ id: 0, name: this.state.preset.name, token: this.state.preset.token, camera: camera });

        if (result.status === 200) {
            let arr = this.state.item.presets;
            arr.push(result.data);

            this.setState({ item: { ...this.state.item, presets: arr }, preset: { name: "", token: "" } });
        }
    }

    async handleCameraRightClick(event) {
        await CameraService.right(this.state.itemId);
    }

    async handleCameraDownClick(event) {
        console.log("handleCameraDownClick");
        await CameraService.down(this.state.itemId);
    }

    async handleCameraStop(event) {
        console.log("handleCameraStop");
        await CameraService.stop(this.state.itemId);
    }

    async handleCameraZoomIn(event) {
        console.log("handleCameraZoomIn");
        await CameraService.zoomIn(this.state.itemId);
    }

    async handleCameraZoomOut(event) {
        console.log("handleCameraZoomOut");
        await CameraService.zoomOut(this.state.itemId);
    }

    render() {
        return (<Modal open={this.props.modalIsOpen} onClose={this.props.onClose} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
            <Box sx={{ ...style, width: this.props.width, height: this.props.height }}>

                <DeleteConfirmationModal modalIsOpen={this.state.deleteModalIsOpen} onConfirmation={this.deletePresetConfirmation} onClose={this.onDeleteClose} itemId={this.state.selectedPresetId} title={this.props.presetsDeleteTitle} text={this.props.presetsDeleteText} confirmationButtonText={this.props.presetsDeleteButtonYes} rejectButtonText={this.props.presetsDeleteButtonNo} width={'50%'} height={'200px'} />

                <div className="row">
                    <div className="col-10">
                        <h2 id="parent-modal-title">{this.props.title}</h2>
                    </div>
                    <div className="col-2">
                        <Button onClick={this.handleOnClose} type="button" variant="contained" sx={{ mt: 0, mb: 0, height: 35, background: "#AA4A44", float: 'right' }}>
                            <FontAwesomeIcon icon={faRemove} />
                        </Button>
                    </div>
                </div>

                <Box component="form" onSubmit={(e) => { this.handleSubmit(e); }} noValidate sx={{ mt: 1 }}>
                    <div className="row" style={{ overflowY: "scroll", height: "500px" }}>
                        <TextField
                            sx={{ mt: 1, width: '96%' }}
                            required
                            id="name"
                            label={this.props.nameText}
                            name="name"
                            type="text"
                            value={this.state.item.name}
                            onChange={(e) => { this.setState({ item: { ...this.state.item, name: e.target.value } }); }} />
                        <TextField
                            sx={{ mt: 1, width: '96%' }}
                            required
                            id="ipAddress"
                            label={this.props.ipAddressText}
                            name="ipAddress"
                            type="text"
                            value={this.state.item.ipAddress}
                            onChange={(e) => { this.setState({ item: { ...this.state.item, ipAddress: e.target.value } }); }} />
                        <TextField
                            sx={{ mt: 1, width: '96%' }}
                            required
                            id="port"
                            label={this.props.portText}
                            name="port"
                            type="text"
                            value={this.state.item.port}
                            onChange={(e) => { this.setState({ item: { ...this.state.item, port: e.target.value } }); }} />
                        {/*<TextField*/}
                        {/*    sx={{ mt: 1, width: '96%' }}*/}
                        {/*    required*/}
                        {/*    id="endpoint"*/}
                        {/*    label={this.props.endpointText}*/}
                        {/*    name="endpoint"*/}
                        {/*    type="text"*/}
                        {/*    value={this.state.item.endpoint}*/}
                        {/*    onChange={(e) => { this.setState({ item: { ...this.state.item, endpoint: e.target.value } }); }} />*/}
                        <TextField
                            sx={{ mt: 1, width: '96%' }}
                            id="login"
                            label={this.props.userNameText}
                            name="login"
                            type="text"
                            value={this.state.item.login}
                            onChange={(e) => { this.setState({ item: { ...this.state.item, login: e.target.value } }); }} />
                        <TextField
                            sx={{ mt: 1, width: '96%' }}
                            id="password"
                            label={this.props.passwordText}
                            name="password"
                            type="password"
                            value={this.state.item.password}
                            onChange={(e) => { this.setState({ item: { ...this.state.item, password: e.target.value } }); }} />

                        <h5 style={{ marginTop: "25px" }} >{this.props.presetsTitle}</h5>

                        <div className="row">
                            {/*<div className="col-lg-6 col-md-6 col-sm-12">*/}
                            {/*    <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />*/}
                            {/*</div>*/}
                            <div className="col-12" style={{ margin: "auto" }}>
                                {/*<div className="row">*/}
                                {/*    <Button onMouseDown={this.handleCameraZoomOut} onMouseUp={this.handleCameraStop} type="button" variant="contained" sx={{ mt: 0, mb: 0, height: 55, width: 55, background: "#AA4A44", margin: "auto", marginLeft: "30%" }}>*/}
                                {/*        <FontAwesomeIcon icon={faMinus} />*/}
                                {/*    </Button>*/}
                                {/*    <Button onMouseDown={this.handleCameraUpClick} onMouseUp={this.handleCameraStop} type="button" variant="contained" sx={{ mt: 0, mb: 0, height: 55, width: 55, background: "#AA4A44", margin: "auto" }}>*/}
                                {/*        <FontAwesomeIcon icon={faArrowUp} />*/}
                                {/*    </Button>*/}
                                {/*    <Button onMouseDown={this.handleCameraZoomIn} onMouseUp={this.handleCameraStop} type="button" variant="contained" sx={{ mt: 0, mb: 0, height: 55, width: 55, background: "#AA4A44", margin: "auto", marginRight: "30%" }}>*/}
                                {/*        <FontAwesomeIcon icon={faPlus} />*/}
                                {/*    </Button>*/}
                                {/*</div>*/}
                                {/*<div className="row" style={{ marginTop: "10px" }}>*/}
                                {/*    <Button onMouseDown={this.handleCameraLeftClick} onMouseUp={this.handleCameraStop} type="button" variant="contained" sx={{ mt: 0, mb: 0, height: 55, width: 55, background: "#AA4A44", margin: "auto", marginLeft: "30%" }}>*/}
                                {/*        <FontAwesomeIcon icon={faArrowLeft} />*/}
                                {/*    </Button>*/}
                                {/*    <Button onClick={this.handleCameraHomeClick} type="button" variant="contained" sx={{ mt: 0, mb: 0, height: 55, width: 55, background: "#AA4A44", margin: "auto" }}>*/}
                                {/*        <FontAwesomeIcon icon={faHome} />*/}
                                {/*    </Button>*/}
                                {/*    <Button onMouseDown={this.handleCameraRightClick} onMouseUp={this.handleCameraStop} type="button" variant="contained" sx={{ mt: 0, mb: 0, height: 55, width: 55, background: "#AA4A44", margin: "auto", marginRight: "30%" }}>*/}
                                {/*        <FontAwesomeIcon icon={faArrowRight} />*/}
                                {/*    </Button>*/}
                                {/*</div>*/}
                                {/*<div className="row" style={{ marginTop: "10px" }}>*/}
                                {/*    <Button onMouseDown={this.handleCameraDownClick} onMouseUp={this.handleCameraStop} type="button" variant="contained" sx={{ mt: 0, mb: 0, height: 55, width: 55, background: "#AA4A44", margin: "auto" }}>*/}
                                {/*        <FontAwesomeIcon icon={faArrowDown} />*/}
                                {/*    </Button>*/}
                                {/*</div>*/}
                                <div className="row" style={{ marginTop: "10px" }}>
                                    <TextField
                                        sx={{ mt: 0, width: '35%' }}
                                        required
                                        id="presetName"
                                        label={this.props.newPresetName}
                                        name="presetName"
                                        type="text"
                                        value={this.state.preset.name}
                                        onChange={(e) => { this.setState({ preset: { ...this.state.preset, name: e.target.value } }); }} />
                                    <TextField
                                        sx={{ mt: 0, ml: "auto", width: '35%' }}
                                        required
                                        id="presetToken"
                                        label={"Preset token"}
                                        name="presetToken"
                                        type="text"
                                        value={this.state.preset.token}
                                        onChange={(e) => { this.setState({ preset: { ...this.state.preset, token: e.target.value } }); }} />
                                    <Button onClick={this.handleCameraSaveClick} type="button" variant="contained" sx={{ mt: 0, mb: 0, ml: "auto", width: '25%', height: 55, background: "#AA4A44", margin: "auto" }}>
                                        <FontAwesomeIcon icon={faSave} /> <span style={{ marginLeft: "10px" }}>{this.props.addButtonText}</span>
                                    </Button>
                                </div>
                            </div>
                        </div>

                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col-2">#</th>
                                    <th scope="col-2">{this.props.presetsName}</th>
                                    <th scope="col-2">Preset token</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.item.presets?.map((preset, key) => {
                                    return <tr key={key}>
                                        <td className="col-2">
                                            <div className="row">
                                                <div className="col-8">
                                                    <Button onClick={(e) => { this.handleDeleteClick(preset.id); }} type="button" variant="contained" sx={{ mt: 0, mb: 0, height: 35, background: "#AA4A44" }}>
                                                        <FontAwesomeIcon icon={faRemove} />
                                                    </Button>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="col-2">{preset.name}</td>
                                        <td className="col-2">{preset.token}</td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className="row">
                        <div className="col-10">
                        </div>
                        <div className="col-1">
                            <Button type="submit" variant="contained" sx={{ mt: 2, mb: 0, mr: 2, height: 35, width: 100, background: "#AA4A44", float: 'right' }}>
                                {this.props.confirmationButtonText}
                            </Button>
                        </div>
                        <div className="col-1">
                            <Button onClick={this.handleOnClose} type="button" variant="contained" sx={{ mt: 2, mb: 0, ml: 2, height: 35, width: 100, background: "#AA4A44", float: 'right' }}>
                                {this.props.rejectButtonText}
                            </Button>
                        </div>
                    </div>
                </Box>
            </Box>
        </Modal >)
    }
}
