import { faRemove } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import React, { Component } from 'react';
import BoardService from "./../../services/board-service";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

export class EditBoardModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            itemId: null,
            item: { id: 0, name: "", url: "", base64: "" }
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleOnClose = this.handleOnClose.bind(this);
    }

    async getBoard() {
        let result = await BoardService.get(this.state.itemId);

        if (result.status === 200) {
            this.setState({ item: result.data }, () => { if (result.data.url == null) this.setState({ item: { ...this.state.item, url: "" } }) });
        }
    }

    getBase64() {
        var file = document.getElementById("file").files[0];

        if (file === undefined) return '';

        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            return reader.result;
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
            return '';
        };
    }

    componentDidUpdate() {
        if (this.props.itemId !== this.state.itemId && this.props.itemId !== null && this.props.itemId !== false && this.props.itemId !== '') {
            this.setState({ itemId: this.props.itemId }, () => { this.getBoard(); });
        }
    }

    async handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        let result = await BoardService.update({ id: this.state.itemId, name: data.get('name'), url: data.get('url'), base64: this.getBase64() });

        if (result.status === 200) {
            this.props.onConfirmation();
        }
    }

    handleOnClose(event) {
        this.props.onClose();
    }

    render() {
        return (<Modal
            open={this.props.modalIsOpen}
            onClose={this.props.onClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box sx={{ ...style, width: this.props.width, height: this.props.height }}>
                <div className="row">
                    <div className="col-10">
                        <h2 id="parent-modal-title">{this.props.title}</h2>
                    </div>
                    <div className="col-2">
                        <Button onClick={this.handleOnClose} type="button" variant="contained" sx={{ mt: 0, mb: 0, height: 35, background: "#AA4A44", float: 'right' }}>
                            <FontAwesomeIcon icon={faRemove} />
                        </Button>
                    </div>
                </div>

                <Box component="form" onSubmit={(e) => { this.handleSubmit(e); }} noValidate sx={{ mt: 1 }}>
                    <img height={100} src={(this.state.item?.url != null && this.state.item?.url?.length > 0) ? this.state.item?.url : "data:image/png;base64," + this.state.item?.base64String} id="boardImage" alt="board" />
                    <TextField
                        sx={{ mt: 1, width: '96%' }}
                        required
                        id="name"
                        label="Nazwa"
                        name="name"
                        type="text"
                        defaultValue={""}
                        value={this.state.item?.name}
                        onChange={(e) => { this.setState({ item: { ...this.state.item, name: e.target.value } }); }} />
                    <TextField
                        sx={{ mt: 1, width: '96%' }}
                        id="url"
                        label="Url"
                        name="url"
                        type="text"
                        defaultValue={""}
                        value={this.state.item?.url}
                        onChange={(e) => { this.setState({ item: { ...this.state.item, url: e.target.value } }); }} />
                    <Button
                        variant="contained"
                        component="label"
                        sx={{ mt: 2, mb: 2, mr: 2, minWidth: '5%', height: 55, background: "#AA4A44" }}
                    >
                        {this.props.selectFileText}
                        <input
                            hidden
                            id="file"
                            label="file"
                            name="file"
                            type="file"
                            accept="image/png, image/gif, image/jpeg"
                            onChange={(e) => { this.setState({ item: { ...this.state.item, base64: this.getBase64() } }) }}
                        />
                    </Button>
                    <div className="row">
                        <div className="col-8">
                        </div>
                        <div className="col-2">
                            <Button type="submit" variant="contained" sx={{ mt: 2, mb: 0, mr: 2, height: 35, width: 100, background: "#AA4A44", float: 'right' }}>
                                {this.props.confirmationButtonText}
                            </Button>
                        </div>
                        <div className="col-2">
                            <Button onClick={this.handleOnClose} type="button" variant="contained" sx={{ mt: 2, mb: 0, ml: 2, height: 35, width: 100, background: "#AA4A44", float: 'right' }}>
                                {this.props.rejectButtonText}
                            </Button>
                        </div>
                    </div>
                </Box>
            </Box>
        </Modal >)
    }
}
