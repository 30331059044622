import { faEdit, faRemove } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@mui/material/Button';
import React, { useState, useEffect } from 'react';
import { DeleteConfirmationModal } from '../../Helpers/DeleteConfirmationModal';
import UserService from "./../../../services/user-service";
import { AddUser } from './AddUser';
import Header from '../Helpers/Header';
import Sidemenu from '../Helpers/Sidemenu';
import { EditGuestModal } from '../../Helpers/EditGuestModal';
import { useTranslation } from "react-i18next";

const Guests = () => {

    const { t } = useTranslation();
    const [users, setUsers] = useState([]);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(false);
    const getUsers = async () => {
        let result = await UserService.getUsers();
        setUsers(result.data);
    }

    const getStatusName = (status) => {
        switch (status) {
            default:
            case 0:
                return t("status.notActivated");
            case 1:
                return t("status.activated");
            case 2:
                return t("status.banned");
            case 3:
                return t("status.deleted");
        }
    }

    const handleCallback = async () => {
        await getUsers();
    }

    const handleEditClick = (uId) => {
        setSelectedUserId(uId);
        setEditModalIsOpen(!editModalIsOpen);
    }

    const handleDeleteClick = (uId) => {
        setSelectedUserId(uId);
        setDeleteModalIsOpen(!deleteModalIsOpen);
    }

    const onDeleteClose = () => {
        setDeleteModalIsOpen(false);
    }

    const deleteUser = () => {
        UserService.deleteUser(selectedUserId);
        setSelectedUserId('');

        setDeleteModalIsOpen(false);
        getUsers();
    }

    const onEditClose = () => {
        setEditModalIsOpen(false);
        getUsers();
    }

    useEffect(() => {
        if (users.length === 0) { getUsers(); }
    });

    return (
        <div>
            <EditGuestModal modalIsOpen={editModalIsOpen} onConfirmation={onEditClose} onClose={onEditClose} itemId={selectedUserId} title={t("guests.edit.title")} emailText={t("guests.edit.email")} firstNameText={t("guests.edit.firstName")} lastNameText={t("guests.edit.lastName")} positionText={t("guests.edit.position")} nonePresetText={t("guests.edit.nonePreset") }  confirmationButtonText={t("button.save")} rejectButtonText={t("button.cancel")} width={'80%'} height={'400px'} />
            <DeleteConfirmationModal modalIsOpen={deleteModalIsOpen} onConfirmation={deleteUser} onClose={onDeleteClose} itemId={selectedUserId} title={t("guests.delete.title")} text={t("guests.delete.text")} confirmationButtonText={t("button.yes")} rejectButtonText={t("button.no")} width={'50%'} height={'200px'} />

            <Header />
            <div className="content">
                <div className="pnazwa">
                    <input type="text" disabled defaultValue={t("guests.title")} size="30" />
                </div>
                <div className="boxes">
                    <hr />
                    <AddUser parentCallback={handleCallback} emailText={t("guests.add.email")} firstNameText={t("guests.add.firstName")} lastNameText={t("guests.add.lastName")} positionText={t("guests.add.position")} addButtonText={t("button.add")} />
                    <hr />
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col-2">#</th>
                                <th scope="col-1">{t("guests.preset")}</th>
                                <th scope="col-1">{t("guests.firstName")}</th>
                                <th scope="col-2">{t("guests.lastName")}</th>
                                <th scope="col-2">{t("guests.position")}</th>
                                <th scope="col-2">{t("guests.email")}</th>
                                <th scope="col-2">{t("guests.status")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((s, i) => {
                                return <tr key={i}>
                                    <td className="col-2">
                                        <div className="row">
                                            <div className="col-4">
                                                <Button onClick={(e) => { handleEditClick(s.id); }} type="button" variant="contained" sx={{ mt: 0, mb: 0, mr: 1, height: 35, background: "#AA4A44" }}>
                                                    <FontAwesomeIcon icon={faEdit} />
                                                </Button>
                                            </div>
                                            <div className="col-4">
                                                <Button onClick={(e) => { handleDeleteClick(s.id); }} type="button" variant="contained" sx={{ mt: 0, mb: 0, height: 35, background: "#AA4A44" }}>
                                                    <FontAwesomeIcon icon={faRemove} />
                                                </Button>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="col-1">{s.preset?.name}</td>
                                    <td className="col-1">{s.firstName}</td>
                                    <td className="col-2">{s.lastName}</td>
                                    <td className="col-2">{s.position}</td>
                                    <td className="col-2">{s.email}</td>
                                    <td className="col-2">{getStatusName(s.status)}</td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            <Sidemenu page="guests" />
        </div>
    );
}

export default Guests;