import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';
import BoardService from '../../../services/board-service';
import { useTranslation } from "react-i18next";

const AddBoard = (props) => {

    const { t } = useTranslation();
    const [name, setName] = useState("");
    const [url, setUrl] = useState("https://placehold.co/600x400");
    const [base64, setBase64] = useState('');

    const getBase64 = () => {
        var file = document.getElementById("file").files[0];
        if (file === undefined) return;

        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setUrl('');
            setBase64(reader.result);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        if (data.get('name').length === 0) {
            alert("Wszystkie pola muszą być wypełnione!")
            return;
        }
        let result = await BoardService.add({ name: name, url: url.length > 0 ? url : null, base64String: base64.length > 0 ? base64 : null });

        if (result.status === 200) {
            props.parentCallback();
            setBase64('');
            setUrl("https://placehold.co/600x400");
            setName("");
        }
    };

    const addForm = () => {
        return <div style={{ textAlign: "left" }}>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                    sx={{ width: '20%', mr: 2 }}
                    margin="normal"
                    required
                    id="name"
                    label={t("board.name")}
                    name="name"
                    type="text"
                    value={name}
                    onChange={(e) => { setName(e.target.value) }} />
                <TextField
                    sx={{ width: '20%', mr: 2 }}
                    margin="normal"
                    id="url"
                    label={t("board.url")}
                    name="url"
                    type="text"
                    value={url}
                    onChange={(e) => { setUrl(e.target.value); setBase64(''); }} />
                <Button
                    variant="contained"
                    component="label"
                    sx={{ mt: 2, mb: 2, mr: 2, minWidth: '5%', height: 55, background: "#AA4A44" }}
                >
                    {t("board.file")}
                    <input
                        id="file"
                        label="file"
                        name="file"
                        accept="image/png, image/gif, image/jpeg"
                        type="file"
                        onChange={(e) => { getBase64(); }}
                        hidden
                    />
                </Button>
                <img style={{ marginRight: 10, height: 55 }} src={url !== '' && url != null ? url : base64} alt="board" />

                <Button
                    style={{ background: "#AA4A44" }}
                    type="submit"
                    variant="contained"
                    sx={{ mt: 2, mb: 2, minWidth: '5%', height: 55 }}
                >
                    {t("button.add")}
                </Button>
            </Box>
        </div>

    }

    return addForm();
}

export default AddBoard;
