import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'api/board/';

class BoardService {

    getall() {
        return axios.get(API_URL + 'getall', { headers: authHeader() });
    }

    get(id) {
        return axios.get(API_URL + 'get/' + id, { headers: authHeader() });
    }

    add(data) {
        return axios.post(API_URL + "add", JSON.stringify(data),
            { headers: authHeader() });
    }

    update(data) {
        return axios.post(API_URL + "update", JSON.stringify(data),
            { headers: authHeader() });
    }

    delete(id) {
        return axios.delete(API_URL + 'delete/' + id, { headers: authHeader() });
    }

    activate(id) {
        return axios.get(API_URL + 'activate/' + id, { headers: authHeader() });
    }

    deactivate() {
        return axios.get(API_URL + 'deactivate', { headers: authHeader() });
    }
}

export default new BoardService();