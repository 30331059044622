import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'api/client/';

class ClientService {

    get() {
        return axios.get(API_URL + 'get', { headers: authHeader() });
    }

    update(data) {
        return axios.post(API_URL + "update", JSON.stringify(data),
            { headers: authHeader() });
    }

}

export default new ClientService();