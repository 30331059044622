import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
    en: {
        translation: {
            sidemenu: {
                dashboard: "Dashboard",
                titles: "Titles",
                guests: "Guests",
                camera: "Cameras",
                boards: "Boards",
                settings: "Settings",
            },
            board: {
                title: "Boards",
                name: "Name",
                image: "Image",
                url: "Url",
                file: "Select from disk",
                edition: {
                    title: "Board edition"
                },
                delete: {
                    title: "Delete confirmation",
                    text: "Are you sure to delete this board?"
                }
            },
            camera: {
                title: "Cameras",
                name: "Name",
                ipAddress: "IP Address",
                port: "Port",
                endpoint: "Endpoint",
                userName: "Username",
                password: "Password",
                edit: {
                    title: "Edit camera",
                    newPresetName: "New preset name",
                    name: "Name",
                    ipAddress: "IP Address",
                    port: "Port",
                    endpoint: "Endpoint",
                    userName: "Username",
                    password: "Password",
                    presets: {
                        title: "Presets",
                        name: "Name",
                        delete: {
                            title: "Delete confirmation",
                            text: "Are you sure to delete this preset?"
                        }
                    },
                    delete: {
                        title: "Delete confirmation",
                        text: "Are you sure to delete this camera?"
                    }
                },
            },
            guests: {
                title: "Guests",
                add: {
                    email: "Email",
                    firstName: "Firstname",
                    lastName: "Lastname",
                    position: "Position",
                },
                edit: {
                    title: "Edit guest",
                    email: "Email",
                    firstName: "Firstname",
                    lastName: "Lastname",
                    position: "Position",
                    nonePreset: "None"
                },
                delete: {
                    title: "Delete confirmation",
                    text: "Are you sure to delete this guest?"
                },
                preset: "Preset",
                email: "Email",
                firstName: "Firstname",
                lastName: "Lastname",
                position: "Position",
                status: "Status"
            },
            dashboard: {
                meetingName: "Meeting name",
                currentSubject: "Current meeting subject",
                guests: "Guests",
                cameraViews: "Camera views",
                meetingPause: "Break in the meeting",
                disableBoard: "Disable board",
                pause: {
                    disable: {
                        title: "Disable pause",
                        text: "Would you like to disable pause?",
                    },
                    set: {
                        title: "Set pause",
                        timeLabelText: "When pause should end?"
                    }
                }
            },
            points: {
                title: "Titles",
                newMeetingPoint: "New meeting point",
                delete: {
                    title: "Delete confirmation",
                    text: "Are you sure to delete this meeting point?"
                }
            },
            settings: {
                title: "Settings",
                language: "Language",
                name: "Main name",
                passwordChange: "Password change",
                newPassword: "New password",
                repeatPassword: "Repeat password",
                oldPassword: "Old password",
                newMeetingPoint: "New meeting point"
            },
            button: {
                save: "Save",
                cancel: "Cancel",
                yes: "Yes",
                no: "No",
                add: "Add",
            },
            status: {
                notActivated: "Not Activated",
                activated: "Activated",
                banned: "Banned",
                deleted: "Deleted"
            },
            login: "Login",
            breakInMeeting: "Break in meeting",
        },
    },
    pl: {
        translation: {
            sidemenu: {
                dashboard: "Pulpit",
                titles: "Tematy",
                guests: "Goście",
                camera: "Kamera",
                boards: "Plansze",
                settings: "Ustawienia",
            },
            board: {
                title: "Plansze",
                name: "Nazwa",
                image: "Obraz",
                url: "Url",
                file: "Wybierz z dysku",
                edition: {
                    title: "Edycja planszy"
                },
                delete: {
                    title: "Potwierdzenie usunięcia",
                    text: "Czy na pewno chcesz usunąć tę planszę?"
                }
            },
            camera: {
                title: "Kamery",
                name: "Nazwa",
                ipAddress: "Adres IP",
                port: "Port",
                endpoint: "Endpoint",
                userName: "Nazwa użytkownika",
                password: "Hasło",
                edit: {
                    title: "Edycja kamery",
                    newPresetName: "Nazwa nowego presetu",
                    name: "Nazwa",
                    ipAddress: "Adres IP",
                    port: "Port",
                    endpoint: "Endpoint",
                    userName: "Nazwa użytkownika",
                    password: "Hasło",
                    presets: {
                        title: "Presety",
                        name: "Nazwa",
                        delete: {
                            title: "Potwierdzenie usunięcia",
                            text: "Czy na pewno chcesz usunąć ten preset?"
                        }
                    },
                    delete: {
                        title: "Potwierdzenie usunięcia",
                        text: "Czy na pewno chcesz usunąć tę planszę?"
                    }
                },

            },
            guests: {
                title: "Goście",
                add: {
                    email: "Email",
                    firstName: "Imię",
                    lastName: "Nazwisko",
                    position: "Stanowisko",
                },
                edit: {
                    title: "Edycja gościa",
                    email: "Email",
                    firstName: "Imię",
                    lastName: "Nazwisko",
                    position: "Stanowisko",
                    nonePreset: "Brak"
                },
                delete: {
                    title: "Potwierdzenie usunięcia",
                    text: "Czy na pewno chcesz usunąć konto gościa?"
                },
                preset: "Preset",
                email: "Email",
                firstName: "Imię",
                lastName: "Nazwisko",
                position: "Stanowisko",
                status: "Status"
            },
            dashboard: {
                meetingName: "Nazwa spotkania",
                currentSubject: "Aktualny temat obrad",
                guests: "Goście",
                cameraViews: "Widoki kamery",
                meetingPause: "Przerwa w obradach",
                disableBoard: "Wyłącz planszę",
                pause: {
                    disable: {
                        title: "Wyłącz przerwę",
                        text: "Czy chciałbyś wyłączyć przerwę?",
                    },
                    set: {
                        title: "Ustaw przerwę",
                        timeLabelText: "Kiedy przerwa powinna się zakończyć?"
                    }
                }
            },
            points: {
                title: "Tematy",
                newMeetingPoint: "Nowy punkt spotkania",
                delete: {
                    title: "Potwierdzenie usunięcia",
                    text: "Czy na pewno chcesz usunąć ten punkt spotkania?"
                }
            },
            settings: {
                title: "Ustawienia",
                language: "Język",
                name: "Główna nazwa",
                passwordChange: "Zmiana hasła",
                newPassword: "Nowe hasło",
                repeatPassword: "Powtórz nowe hasło",
                oldPassword: "Stare hasło",
                newMeetingPoint: "Nowy punkt spotkania"
            },
            button: {
                save: "Zapisz",
                cancel: "Anuluj",
                yes: "Tak",
                no: "Nie",
                add: "Dodaj",
            },
            status: {
                notActivated: "Nie aktywowany",
                activated: "Aktywowany",
                banned: "Zbanowany",
                deleted: "Usunięty"
            },
            login: "Zaloguj",
            breakInMeeting: "Przerwa w obradach",
        },
    },
    de: {
        translation: {
            sidemenu: {
                dashboard: "Dashboard",
                titles: "Titel",
                guests: "Gäste",
                camera: "Kameras",
                boards: "Tafeln",
                settings: "Einstellungen",
            },
            board: {
                title: "Tafeln",
                name: "Name",
                image: "Bild",
                url: "URL",
                file: "Von der Festplatte auswählen",
                edition: {
                    title: "Tafelbearbeitung"
                },
                delete: {
                    title: "Löschbestätigung",
                    text: "Sind Sie sicher, dass Sie diese Tafel löschen möchten?"
                }
            },
            camera: {
                title: "Kameras",
                name: "Name",
                ipAddress: "IP-Adresse",
                port: "Port",
                endpoint: "Endpunkt",
                userName: "Benutzername",
                password: "Passwort",
                edit: {
                    title: "Kamera bearbeiten",
                    newPresetName: "Neuer Voreinstellungsname",
                    name: "Name",
                    ipAddress: "IP-Adresse",
                    port: "Port",
                    endpoint: "Endpunkt",
                    userName: "Benutzername",
                    password: "Passwort",
                    presets: {
                        title: "Voreinstellungen",
                        name: "Name",
                        delete: {
                            title: "Löschbestätigung",
                            text: "Sind Sie sicher, dass Sie diese Voreinstellung löschen möchten?"
                        }
                    },
                    delete: {
                        title: "Löschbestätigung",
                        text: "Sind Sie sicher, dass Sie diese Kamera löschen möchten?"
                    }
                },
            },
            guests: {
                title: "Gäste",
                add: {
                    email: "E-Mail",
                    firstName: "Vorname",
                    lastName: "Nachname",
                    position: "Position",
                },
                edit: {
                    title: "Gast bearbeiten",
                    email: "E-Mail",
                    firstName: "Vorname",
                    lastName: "Nachname",
                    position: "Position",
                    nonePreset: "Keine"
                },
                delete: {
                    title: "Löschbestätigung",
                    text: "Sind Sie sicher, dass Sie diesen Gast löschen möchten?"
                },
                preset: "Voreinstellung",
                email: "E-Mail",
                firstName: "Vorname",
                lastName: "Nachname",
                position: "Position",
                status: "Status"
            },
            dashboard: {
                meetingName: "Meeting-Name",
                currentSubject: "Aktuelles Besprechungsthema",
                guests: "Gäste",
                cameraViews: "Kamerablickwinkel",
                meetingPause: "Pause in der Besprechung",
                disableBoard: "Tafel deaktivieren",
                pause: {
                    disable: {
                        title: "Pause deaktivieren",
                        text: "Möchten Sie die Pause deaktivieren?",
                    },
                    set: {
                        title: "Pause einstellen",
                        timeLabelText: "Wann soll die Pause enden?"
                    }
                }
            },
            points: {
                title: "Titel",
                newMeetingPoint: "Neuer Besprechungspunkt",
                delete: {
                    title: "Löschbestätigung",
                    text: "Sind Sie sicher, dass Sie diesen Besprechungspunkt löschen möchten?"
                }
            },
            settings: {
                title: "Einstellungen",
                language: "Sprache",
                name: "Hauptname",
                passwordChange: "Passwortänderung",
                newPassword: "Neues Passwort",
                repeatPassword: "Passwort wiederholen",
                oldPassword: "Altes Passwort",
                newMeetingPoint: "Neuer Besprechungspunkt"
            },
            button: {
                save: "Speichern",
                cancel: "Abbrechen",
                yes: "Ja",
                no: "Nein",
                add: "Hinzufügen",
            },
            status: {
                notActivated: "Nicht aktiviert",
                activated: "Aktiviert",
                banned: "Gesperrt",
                deleted: "Gelöscht"
            },
            login: "Anmelden",
            breakInMeeting: "Besprechungspause",
        },
    },
};

i18n.use(initReactI18next).init({
    resources,
    lng: "pl",
    fallbackLng: "pl",
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;