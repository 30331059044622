import { Container } from 'reactstrap';
import Dashboard from "./components/Account/Home/Dashboard";
import Settings from "./components/Account/Settings/Settings";
import Forgot from "./components/Forgot";
import Login from "./components/Menu/Login";
import { Register } from "./components/Menu/Register";
import Streamer from './components/Menu/Streamer';
import { NotFound } from './components/NotFound';
import Remind from './components/Remind';
import Authentication from "./provider/Authentication";
import Guests from './components/Account/Guests/Guests';
import Points from './components/Account/Meeting/Points';
import Cameras from './components/Account/Cameras/Cameras';
import Boards from './components/Account/Boards/Boards';

const AppRoutes = [
    {
        path: '/',
        element: <Authentication><Dashboard /></Authentication>
    },
    {
        path: '/points',
        element: <Authentication><Points /></Authentication>
    },
    {
        path: '/guests',
        element: <Authentication><Guests /></Authentication>
    },
    {
        path: '/boards',
        element: <Authentication><Boards /></Authentication>
    },
    {
        path: '/cameras',
        element: <Authentication><Cameras /></Authentication>
    },
    {
        path: '/settings',
        element: <Authentication><Settings /></Authentication>
    },
    {
        path: '/login',
        element: <Container tag="main"><Login /></Container>
    },
    //{
    //    path: '/register',
    //    element: <Container tag="main"><Register /></Container>
    //},
    {
        path: '/forgot',
        element: <Container tag="main"><Forgot /></Container>
    },
    {
        path: '/remind/:token',
        element: <Container tag="main"><Remind /></Container>
    },
    {
        path: '/streamer/:token',
        element: <Streamer />
    },
    {
        path: '*',
        element: <Container tag="main"><NotFound /></Container>
    }
];

export default AppRoutes;
