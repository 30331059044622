import { faRemove } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import React, { Component } from 'react';
import UserService from "./../../services/user-service";
import CameraService from "./../../services/camera-service";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

export class EditGuestModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            itemId: null,
            item: { firstName: "", lastName: "", position: "", presetId: 0 },
            presets: []
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleOnClose = this.handleOnClose.bind(this);

        this.getPresets();
    }

    async getPresets() {
        let result = await CameraService.getPresets();
        if (result.status === 200) {
            this.setState({ presets: result.data });
        }
    }

    async getGuest() {
        let result = await UserService.getUser(this.state.itemId);

        if (result.status === 200) {
            this.setState({ item: result.data });
        }
    }



    componentDidUpdate() {
        if (this.props.itemId !== this.state.itemId && this.props.itemId !== null && this.props.itemId !== false && this.props.itemId !== '') {
            this.setState({ itemId: this.props.itemId }, () => { this.getGuest(); });
        }
    }

    async handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        if (data.get('firstName').length === 0 || data.get('lastName').length === 0 || data.get('position').length === 0) {
            alert("Wszystkie pola muszą być wypełnione!")
            return;
        }

        let result = await UserService.updateUser({ id: this.state.itemId, firstName: data.get('firstName'), lastName: data.get('lastName'), position: data.get('position'), presetId: data.get('preset') });

        if (result.status === 200) {
            this.props.onConfirmation();
        }
    }

    handleOnClose(event) {
        this.props.onClose();
    }

    render() {
        return (<Modal
            open={this.props.modalIsOpen}
            onClose={this.props.onClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box sx={{ ...style, width: this.props.width, height: this.props.height }}>
                <div className="row">
                    <div className="col-10">
                        <h2 id="parent-modal-title">{this.props.title}</h2>
                    </div>
                    <div className="col-2">
                        <Button onClick={this.handleOnClose} type="button" variant="contained" sx={{ mt: 0, mb: 0, height: 35, background: "#AA4A44", float: 'right' }}>
                            <FontAwesomeIcon icon={faRemove} />
                        </Button>
                    </div>
                </div>

                <Box component="form" onSubmit={(e) => { this.handleSubmit(e); }} noValidate sx={{ mt: 1 }}>
                    <TextField
                        sx={{ mt: 1, width: '96%' }}
                        required
                        id="firstName"
                        label={this.props.firstNameText}
                        name="firstName"
                        type="text"
                        value={this.state.item?.firstName}
                        onChange={(e) => { this.setState({ item: { ...this.state.item, firstName: e.target.value } }); }} />
                    <TextField
                        sx={{ mt: 1, width: '96%' }}
                        required
                        id="lastName"
                        label={this.props.lastNameText}
                        name="lastName"
                        type="text"
                        value={this.state.item?.lastName}
                        onChange={(e) => { this.setState({ item: { ...this.state.item, lastName: e.target.value } }); }} />
                    <TextField
                        sx={{ mt: 1, width: '96%' }}
                        required
                        id="position"
                        label={this.props.positionText}
                        name="position"
                        type="text"
                        value={this.state.item?.position}
                        onChange={(e) => { this.setState({ item: { ...this.state.item, position: e.target.value } }); }} />

                    <FormControl fullWidth>
                        <InputLabel sx={{ ml: 2 }} variant="standard" htmlFor="uncontrolled-native">
                            {this.props.presetText}
                        </InputLabel>
                        <Select
                            style={{ marginTop: '10px', width: '96%' }}
                            required
                            id="preset"
                            label={this.props.presetText}
                            name="preset"
                            value={this.state.item?.presetId}
                            onChange={(e) => this.setState({ item: { ...this.state.item, presetId: e.target.value } })}>
                            <MenuItem value={0}>{this.props.nonePresetText}</MenuItem>
                            {
                                this.state.presets?.map((preset, i) => { return <MenuItem key={i} value={preset.id}>{preset.name}</MenuItem> })
                            }
                        </Select>
                    </FormControl>
                   
                    <div className="row">
                        <div className="col-10">
                        </div>
                        <div className="col-1">
                            <Button type="submit" variant="contained" sx={{ mt: 2, mb: 0, mr: 2, height: 35, width: 100, background: "#AA4A44", float: 'right' }}>
                                {this.props.confirmationButtonText}
                            </Button>
                        </div>
                        <div className="col-1">
                            <Button onClick={this.handleOnClose} type="button" variant="contained" sx={{ mt: 2, mb: 0, ml: 2, height: 35, width: 100, background: "#AA4A44", float: 'right' }}>
                                {this.props.rejectButtonText}
                            </Button>
                        </div>
                    </div>
                </Box>
            </Box>
        </Modal >)
    }
}
