import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import RefreshRedux from './components/Helpers/RefreshRedux';
import { Layout } from './components/Layout';
import './custom.css';
import { I18nextProvider } from "react-i18next";
import i18n from "./multilanguage/i18n";

const App = () => {
    const [loading, setLoading] = useState(true);

    if (loading === true) {
        RefreshRedux();
        setLoading(false);
    }

    i18n.init({
        lng: JSON.parse(localStorage.getItem('user'))?.uiLanguage ?? "pl"
    });



    return (
        <I18nextProvider i18n={i18n}>
            <Layout>
                <Routes>
                    {AppRoutes.map((route, index) => {
                        const { element, ...rest } = route;
                        return <Route key={index} {...rest} element={element} />;
                    })}
                </Routes>
            </Layout>
        </I18nextProvider>
    );
}

export default App;
