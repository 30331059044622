import React, { useEffect } from "react";

const parseJwt = (token) => {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
};

const AuthVerify = (props) => {

    const verify = () => {
        const user = JSON.parse(localStorage.getItem("user"));

        if (user !== null) {
            const decodedJwt = parseJwt(user.token);
            return decodedJwt.exp * 1000 < Date.now() ? false : true;
        } else {
            return false;
        }
    }

    return verify();

};

export default AuthVerify;