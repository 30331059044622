import { createSlice } from '@reduxjs/toolkit'

export const clientSlice = createSlice({
    name: 'client',
    initialState: { value: null },
    reducers: {
        setClient: (state, action) => {
            state.value = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const { setClient } = clientSlice.actions

export default clientSlice.reducer