import React, { useState, useEffect } from 'react';
import Sidemenu from '../Helpers/Sidemenu';
import Header from '../Helpers/Header';
import MeetingService from '../../../services/meeting-service';
import UserService from "./../../../services/user-service";
import CameraService from "./../../../services/camera-service";
import BoardService from "./../../../services/board-service";
import { useTranslation } from "react-i18next";
import Countdown from 'react-countdown';
import { DeleteConfirmationModal } from '../../Helpers/DeleteConfirmationModal';
import { SetPauseModal } from '../../Helpers/SetPauseModal';
import { faPause } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OnvifService from '../../../services/onvif-service';

const Dashboard = () => {

    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [meeting, setMeeting] = useState({ name: "", points: [], pauseTo: (Date.now() + 1000) });
    const [boards, setBoards] = useState([]);
    const [users, setUsers] = useState([]);
    const [cameras, setCameras] = useState([]);
    const [selectedPoint, setSelectedPoint] = useState(0);
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [setPauseModal, setSetPauseModal] = useState(false);

    const getMeeting = async () => {
        let result = await MeetingService.getMeeting();

        if (result.status === 200) {
            setMeeting(result.data);
            setSelectedPoint(result.data.points.find(p => p.isActive === true).id);
        }
    }

    const getUsers = async () => {
        let result = await UserService.getUsers();

        if (result.status === 200) {
            setUsers(result.data);
        }
    }

    const getCameras = async () => {
        let result = await CameraService.getall();

        if (result.status === 200) {
            setCameras(result.data);
        }
    }

    const getBoards = async () => {
        let result = await BoardService.getall();

        if (result.status === 200) {
            setBoards(result.data);
        }
    }

    const handleMeetingNameChange = (e) => {
        sendMeetingNameChangeRequest({ ...meeting, name: e.target.value });
    }

    const sendMeetingNameChangeRequest = async (currentMeting) => {

        let result = await MeetingService.updateMeeting(currentMeting);

        if (result.status === 200) {
            setMeeting(currentMeting);
        } else {
            alert("bad request sendMeetingNameChangeRequest");
        }
    }

    const handlePointActivation = async (e) => {
        setSelectedPoint(e.currentTarget.value);

        let result = await MeetingService.activatePoint(e.currentTarget.value);

        if (result.status === 200) {
            setMeeting({ ...meeting, points: result.data });
        } else {
            alert("bad request sendOrderRequest");
        }
    }

    const handlePresetChange = async (pId) => {

        if (pId === undefined || pId === null) return;

        let preset = undefined;

        for (let c = 0; cameras.length > c; c++) {
            for (let p = 0; cameras[c].presets.length > p; p++) {
                if (cameras[c].presets[p].id === pId) {
                    preset = cameras[c].presets[p];
                    continue;
                }
            }
        }

        let camera = cameras.find(c => c.id == preset.cameraId);
        await OnvifService.goToPreset(camera.login, camera.password, camera.ipAddress, preset.token);
    }

    const handleBoardChange = async (e, bId) => {
        let result = bId == null ? await BoardService.deactivate() : await BoardService.activate(bId);

        if (result.status === 200) {
            console.log("success handleBoardChange");
        } else {
            alert("bad request handleBoardChange");
        }
    }

    const handleSpeakerChange = async (e, uId) => {
        let result = uId === null ? await UserService.disableSpeaker() : await UserService.setSpeaker(uId);

        if (result.status === 200) {

            if (users.find(u => u.id == uId)) {

                handlePresetChange(users.find(u => u.id == uId).preset?.id)
            }

            getUsers();
            console.log("success handleSpeakerChange");
        } else {
            alert("bad request handleSpeakerChange");
        }
    }

    const handlePauseClick = async (time) => {

        var ticks = (time - Date.now());
        let minutes = (ticks / 60000);
        let result = await MeetingService.pause(parseInt(minutes));

        if (result.status === 200) {
            setMeeting(result.data);
            setSetPauseModal(false);
        } else {
            alert("bad request handlePauseClick");
        }
    }

    const disablePauseClick = async (e) => {
        let result = await MeetingService.disablePause();

        if (result.status === 200) {
            meeting.pauseTo = Date.now();
            setConfirmationModal(false);

        } else {
            alert("bad request disablePauseClick");
        }
    }

    useEffect(() => {
        if (loading === true) {
            getMeeting();
            getUsers();
            getCameras();
            getBoards();
            setLoading(false);
        }
    });

    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return <div onClick={(e) => { setSetPauseModal(true); }} className="box przerwa w-100" style={{ overflow: "hidden", marginBottom: "15px" }}>
                <h1><FontAwesomeIcon icon={faPause} /> {t("dashboard.meetingPause")}</h1>
            </div>;
        } else {
            return <div onClick={(e) => { setConfirmationModal(true); }} className="box przerwa w-100" style={{ overflow: "hidden", marginBottom: "15px" }}>
                <h1><FontAwesomeIcon icon={faPause} /> {t("dashboard.meetingPause")} {hours < 10 ? "0" + hours : hours}:{minutes < 10 ? "0" + minutes : minutes}:{seconds < 10 ? "0" + seconds : seconds}</h1>
            </div>;
        }
    };

    return (
        <div>
            <DeleteConfirmationModal modalIsOpen={confirmationModal} onConfirmation={disablePauseClick} onClose={() => { setConfirmationModal(false); }} title={t("dashboard.pause.disable.title")} text={t("dashboard.pause.disable.text")} confirmationButtonText={t("button.yes")} rejectButtonText={t("button.no")} width={'50%'} height={'200px'} />
            <SetPauseModal modalIsOpen={setPauseModal} onConfirmation={handlePauseClick} onClose={() => { setSetPauseModal(false); }} title={t("dashboard.pause.set.title")} timeLabelText={t("dashboard.pause.set.timeLabelText")} confirmationButtonText={t("button.save")} rejectButtonText={t("button.cancel")} width={'40%'} height={'200px'} />

            <Header />
            <div className="content">
                <div className="pnazwa">
                    <input type="text" defaultValue={meeting.name} size="30" onChange={handleMeetingNameChange} />
                    <label>{t("dashboard.meetingName")}</label>
                    <select className="punkty" onChange={handlePointActivation} value={selectedPoint}>
                        {meeting.points.map((point, key) => {
                            return <option key={key} value={point.id}>{point.name}</option>
                        })}
                    </select>
                    <label>{t("dashboard.currentSubject")}</label>
                </div>

                <div className="boxes">

                    <div className="row h-100">
                        <div className="col-6 h-100">
                            <div className="box w-100" style={{ height: "90%" }}>
                                <h1><i className="fa fa-user" aria-hidden="true"></i> {t("dashboard.guests")}</h1>
                                <div className="lgoscie">
                                    {users.map((user, key) => {
                                        return <div onClick={(e) => handleSpeakerChange(e, (user.status === 4 ? null : user.id))} key={key} className={user.status === 4 ? "gosc gosc-active" : "gosc"}>{user.firstName} {user.lastName}<span>{user.position}</span></div>;
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="col-6 h-100">
                            <div className="row" style={{ maxHeight: "calc(100% - 200px)", paddingRight: "50px", paddingBottom: "0px", height: "90%", display: "flex", flexFlow: "column" }}>
                                <Countdown key={meeting.pauseTo} date={meeting.pauseTo} renderer={renderer} />
                                <div className="w-100" style={{ paddingLeft: "0px", flex: "0 1 auto" }}>
                                    <div onClick={(e) => handleBoardChange(e, null)} className="plansza wylacz">{t("dashboard.disableBoard")}</div>
                                    {boards.map((board, key) => {
                                        return <div key={key} onClick={(e) => handleBoardChange(e, board.id)} className="plansza" style={{ backgroundImage: "url('" + (board.url != null ? board.url : "data:image/png;base64," + board.base64String) + "')" }}>{board.name}</div>
                                    })}
                                </div>

                                <div className="box w-100" style={{ flex: "1 1 auto" }}>
                                    <h1><i className="fa fa-video-camera" aria-hidden="true"></i> {t("dashboard.cameraViews")}</h1>
                                    <div className="lkamery">
                                        {cameras.map((camera, key) => {
                                            return camera.presets?.map((preset, key) => {
                                                return <div onClick={(e) => handlePresetChange(preset.id)} key={preset.id} className="kamera">{preset.name}</div>
                                            })
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Sidemenu page="dashboard" />
        </div>
    );
}
export default Dashboard;