import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { Component } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import AuthService from "./../../services/auth-service";

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {`Copyright ${String.fromCodePoint(0x00A9)} `}
            <Link color="inherit" href="https://esitzung.pl/">
                esitzung.pl
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();
export class Register extends Component {
    render() {
        return (
            <ThemeProvider theme={defaultTheme}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Rejestracja
                        </Typography>
                        <Box component="form" onSubmit={this.handleSubmit} noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                autoFocus
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="uname"
                                label="Usename"
                                name="uname"
                                autoFocus
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Hasło"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Powtórzenie hasła"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            />

                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <h4 className="mb-3 main-color-text">Osoba kontaktowa</h4>
                            </div>

                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="fname"
                                label="Imię"
                                type="text"
                                id="fname"
                                autoComplete="text"
                            />

                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="lname"
                                label="Nazwisko"
                                type="text"
                                id="lname"
                                autoComplete="text"
                            />

                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="phone"
                                label="Telefon kontaktowy"
                                type="text"
                                id="phone"
                                autoComplete="text"
                            />

                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <h4 className="mb-3 main-color-text">Dane firmy</h4>
                            </div>
                            <Select
                                required
                                fullWidth
                                id="ctype"
                                label="Typ firmy"
                                name="ctype"
                            >
                                <MenuItem value="0">Spółka prawa handlowego (faktura VAT)</MenuItem>
                                <MenuItem value="1">Spółka cywilna/działalność gospodarcza (faktura VAT)</MenuItem>
                            </Select>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="cname"
                                label="Nazwa"
                                type="text"
                                id="cname"
                                autoComplete="text"
                            />

                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="nip"
                                label="NIP"
                                type="text"
                                id="nip"
                                autoComplete="text"
                            />

                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <h4 className="mb-3 main-color-text">Address</h4>
                            </div>

                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="street"
                                label="Ulica"
                                type="text"
                                id="street"
                                autoComplete="text"
                            />

                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="bnumber"
                                label="Numer budynku"
                                type="text"
                                id="bnumber"
                                autoComplete="text"
                            />

                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="lnumber"
                                label="Numer lokalu"
                                type="text"
                                id="lnumber"
                                autoComplete="text"
                            />

                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="zipCode"
                                label="Kod pocztowy"
                                type="text"
                                id="zipCode"
                                autoComplete="text"
                            />

                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="city"
                                label="Miasto"
                                type="text"
                                id="city"
                                autoComplete="text"
                            />

                            <FormControlLabel fullWidth required labelPlacement="bottom end" control={<Checkbox />} label="Przyjmuje do wiadomości, iż w przypadku Przedsiębiorcy- Konsumenta* pierwsze skuteczne zasilenie punktami konta w serwisie Justsend w terminie 14 dni od zarejestrowania będzie równoznaczne z wyrażeniem zgody na rozpoczęcie spełnienia świadczenia przez Digital Virgo Polska S.A. przed upływem terminu do odstąpienia od umowy i wyrażam na to zgodę. Jednocześnie potwierdzam, że jestem świadomy utraty prawa do odstąpienia od umowy w chwili dokonania ww. zasilenia." />

                            <FormControlLabel sx={{ mt: 2 }} fullWidth required control={<Checkbox />} label="Zapoznałem się z treścią <b>Regulaminu</b> i akceptuję jego postanowienia. Administratorem danych osobowych osób zarejestrowanych w serwisie jest Digital Virgo Polska S.A. („Administrator”), Al. Jana Pawła II 22, 00-133 Warszawa, tel. 22 312-1000, fax. 22 312-1001, e-mail: pomoc@justsend.pl. Dane te będą przetwarzane zgodnie z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 roku w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE. Podanie danych osobowych jest dobrowolne, ale niezbędne do rejestracji i świadczenia usług w serwisie Justsend. Osoba, której dane dotyczą ma prawo dostępu do treści swoich danych i ich sprostowania, usunięcia, ograniczenia przetwarzania, prawo do przenoszenia danych oraz prawo do wniesienia sprzeciwu wobec przetwarzania, a także prawo wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych, gdy uzna, iż przetwarzanie jej danych osobowych narusza przepisy Rozporządzenia." />

                            <Button
                                style={{ background: "#AA4A44" }}
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Register
                            </Button>

                            <Grid container className="mt-4">
                                <Grid item xs>
                                    <Link href="/forgot" variant="body2">
                                        Forgot password?
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link href="/login" variant="body2">
                                        {"Have an account already? Sign in"}
                                    </Link>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Copyright sx={{ mt: 8, mb: 4 }} />
                </Container>
            </ThemeProvider>

        );
    }

    async handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let result = await AuthService.register(data.get('email'), data.get('password'), data.get('uname'), data.get('fname'), data.get('lname'), data.get('phone'), data.get('cname'), data.get('ctype'), data.get('nip'), data.get('street'), data.get('bnumber'), data.get('lnumber'), data.get('zipCode'), data.get('city'));
        console.log(result);
    };
}
