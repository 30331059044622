import React from 'react';
import { useSelector } from 'react-redux';

const Header = () => {
    const user = useSelector((state) => state.user.value);
    const client = useSelector((state) => state.client.value);

    return (
        <div className="header">
            <div className="nazwa">{client?.name}</div>
            <div className="user">
                <img align="absmiddle" src={client?.base64String !== undefined ? "data:image/png;base64," + client?.base64String : "https://placehold.co/600x400"} alt="logo" />{user?.firstName} {user?.lastName}</div>
        </div>
    );
}

export default Header;