import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'api/stream/';

class StreamService {

    get(token) {
        return axios.get(API_URL + 'get/' + token, { headers: authHeader() });
    }
}

export default new StreamService();