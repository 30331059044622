import { faRemove } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import React, { Component } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

export class SetPauseModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            time: null
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleOnClose = this.handleOnClose.bind(this);
    }

    async handleSubmit(event) {
        event.preventDefault();
        this.props.onConfirmation(this.state.time);
    }

    handleOnClose(event) {
        this.props.onClose();
    }

    render() {
        return (<Modal
            open={this.props.modalIsOpen}
            onClose={this.props.onClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box sx={{ ...style, width: this.props.width, height: this.props.height }}>
                <div className="row">
                    <div className="col-10">
                        <h2 id="parent-modal-title">{this.props.title}</h2>
                    </div>
                    <div className="col-2">
                        <Button onClick={this.handleOnClose} type="button" variant="contained" sx={{ mt: 0, mb: 0, height: 35, background: "#AA4A44", float: 'right' }}>
                            <FontAwesomeIcon icon={faRemove} />
                        </Button>
                    </div>
                </div>

                <Box component="form" onSubmit={(e) => { this.handleSubmit(e); }} noValidate sx={{ mt: 1 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker id="time" name="time" label={this.props.timeLabelText} value={this.state.time} onChange={(newValue) => this.setState({ time: newValue })} />
                    </LocalizationProvider>
                    <div className="row">
                        <div className="col-8">
                        </div>
                        <div className="col-2">
                            <Button type="submit" variant="contained" sx={{ mt: 2, mb: 0, mr: 2, height: 35, width: 100, background: "#AA4A44", float: 'right' }}>
                                {this.props.confirmationButtonText}
                            </Button>
                        </div>
                        <div className="col-2">
                            <Button onClick={this.handleOnClose} type="button" variant="contained" sx={{ mt: 2, mb: 0, ml: 2, height: 35, width: 100, background: "#AA4A44", float: 'right' }}>
                                {this.props.rejectButtonText}
                            </Button>
                        </div>
                    </div>
                </Box>
            </Box>
        </Modal >)
    }
}
