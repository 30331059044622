import { faRemove } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';
import { DeleteConfirmationModal } from '../../Helpers/DeleteConfirmationModal';
import { EditBoardModal } from '../../Helpers/EditBoardModal';
import Header from '../Helpers/Header';
import Sidemenu from '../Helpers/Sidemenu';
import BoardService from "./../../../services/board-service";
import AddBoard from './AddBoard';
import { useTranslation } from "react-i18next";

const Boards = () => {

    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [boards, setBoards] = useState([]);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [selectedBoardId, setSelectedBoardId] = useState('');

    const getBoards = async () => {
        let result = await BoardService.getall();
        setBoards(result.data);
        setLoading(false);
    }

    const handleCallback = async () => {
        await getBoards();
    }

    const handleEditClick = (uId) => {
        setSelectedBoardId(uId);
        setEditModalIsOpen(!editModalIsOpen);
    }

    const handleDeleteClick = (uId) => {
        setSelectedBoardId(uId);
        setDeleteModalIsOpen(!deleteModalIsOpen);
    }

    const onDeleteClose = () => {
        setDeleteModalIsOpen(false);
    }

    const deleteBoard = async () => {
        await BoardService.delete(selectedBoardId);
        setSelectedBoardId('');

        setDeleteModalIsOpen(false);
        getBoards();
    }

    const onEditClose = () => {
        setEditModalIsOpen(false);
        getBoards();
    }

    useEffect(() => {
        if (loading) { getBoards(); }
    });

    return (
        <div>
            <EditBoardModal modalIsOpen={editModalIsOpen} onConfirmation={onEditClose} onClose={onEditClose} itemId={selectedBoardId} title={t("board.edition.title")} selectFileText={t("board.file")} confirmationButtonText={t("button.save")} rejectButtonText={t("button.cancel")} width={'50%'} height={'65%'} />
            <DeleteConfirmationModal modalIsOpen={deleteModalIsOpen} onConfirmation={deleteBoard} onClose={onDeleteClose} itemId={selectedBoardId} title={t("board.delete.title")} text={t("board.delete.text")} confirmationButtonText={t("button.yes")} rejectButtonText={t("button.no")} width={'50%'} height={'25%'} />

            <Header />
            <div className="content">
                <div className="pnazwa">
                    <input type="text" disabled defaultValue={t("board.title")} size="30" />
                </div>
                <div className="boxes">
                    <hr />
                    <AddBoard parentCallback={handleCallback} />
                    <hr />
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col-2">#</th>
                                <th scope="col-6">{t("board.name")}</th>
                                <th scope="col-4">{t("board.image")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {boards.map((board, i) => {
                                return <tr key={i}>
                                    <td className="col-2" style={{ verticalAlign: "middle" }}>
                                        <div className="row">
                                            {/*<div className="col-4">*/}
                                            {/*    <Button onClick={(e) => { handleEditClick(board.id); }} type="button" variant="contained" sx={{ mt: 0, mb: 0, mr: 1, height: 35, background: "#AA4A44" }}>*/}
                                            {/*        <FontAwesomeIcon icon={faEdit} />*/}
                                            {/*    </Button>*/}
                                            {/*</div>*/}
                                            <div className="col-4">
                                                <Button onClick={(e) => { handleDeleteClick(board.id); }} type="button" variant="contained" sx={{ mt: 0, mb: 0, height: 35, background: "#AA4A44" }}>
                                                    <FontAwesomeIcon icon={faRemove} />
                                                </Button>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="col-6" style={{ verticalAlign: "middle" }}>{board.name}</td>
                                    <td className="col-4"><img height={50} src={board.url != null ? board.url : "data:image/png;base64," + board.base64String} alt="board" /></td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            <Sidemenu page="boards" />
        </div>
    );
}

export default Boards;