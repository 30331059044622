import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import React, { Component } from 'react';
import UserService from '../../../services/user-service';

export class AddUser extends Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        if (data.get('email').length === 0 || data.get('firstName').length === 0 || data.get('lastName').length === 0 || data.get('position').length === 0) {
            alert("Wszystkie pola muszą być wypełnione!")
            return;
        }

        let result = await UserService.addUser({ email: data.get('email'), firstName: data.get('firstName'), lastName: data.get('lastName'), position: data.get('position') });

        if (result.data.status === 0) {
            this.props.parentCallback();
            document.getElementById("email").value = "";
            document.getElementById("firstName").value = "";
            document.getElementById("lastName").value = "";
            document.getElementById("position").value = "";
        }
    };

    addForm() {
        return <div style={{ textAlign: "left" }}>
            <Box component="form" onSubmit={this.handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                    sx={{ width: '20%', mr: 2 }}
                    margin="normal"
                    required
                    id="email"
                    label="Email"
                    name="email"
                    type="email"
                />
                <TextField
                    sx={{ width: '20%', mr: 2 }}
                    margin="normal"
                    required
                    id="firstName"
                    label={this.props.firstNameText}
                    name="firstName"
                    type="text"
                />
                <TextField
                    sx={{ width: '20%', mr: 2 }}
                    margin="normal"
                    required
                    id="lastName"
                    label={this.props.lastNameText}
                    name="lastName"
                    type="text"
                />
                <TextField
                    sx={{ width: '20%', mr: 2 }}
                    margin="normal"
                    required
                    id="position"
                    label={this.props.positionText}
                    name="position"
                    type="text"
                />
                <Button
                    style={{ background: "#AA4A44" }}
                    type="submit"
                    variant="contained"
                    sx={{ mt: 2, mb: 2, minWidth: '5%', height: 55 }}
                >
                    {this.props.addButtonText}
                </Button>
            </Box>
        </div>

    }

    render() {
        return this.addForm();
    }
}
