import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'api/camera/';

class CameraService {

    getall() {
        return axios.get(API_URL + 'getall', { headers: authHeader() });
    }

    get(cId) {
        return axios.get(API_URL + 'get/' + cId, { headers: authHeader() });
    }

    update(data) {
        return axios.post(API_URL + "update", JSON.stringify(data), { headers: authHeader() });
    }

    //presets
    getPreset(pId) {
        return axios.get(API_URL + 'preset/get/' + pId, { headers: authHeader() });
    }

    getPresets() {
        return axios.get(API_URL + 'preset/getall', { headers: authHeader() });
    }

    addPreset(data) {
        return axios.post(API_URL + "preset/add", JSON.stringify(data), { headers: authHeader() });
    }

    updatePreset(data) {
        return axios.post(API_URL + "preset/update", JSON.stringify(data), { headers: authHeader() });
    }

    deletePreset(pId) {
        return axios.delete(API_URL + 'preset/delete/' + pId, { headers: authHeader() });
    }

    //user preset
    addUserPreset(pId, uId) {
        return axios.post(API_URL + "user/add/" + pId + "/" + uId, { headers: authHeader() });
    }

    deleteUserPreset(uId) {
        return axios.delete(API_URL + 'user/delete/' + uId, { headers: authHeader() });
    }

    setPreset(pId) {
        return axios.get(API_URL + "preset/set/" + pId, { headers: authHeader() });
    }

    up(cameraId) {
        return axios.get(API_URL + "up/" + cameraId, { headers: authHeader() });
    }

    left(cameraId) {
        return axios.get(API_URL + "left/" + cameraId, { headers: authHeader() });
    }

    right(cameraId) {
        return axios.get(API_URL + "right/" + cameraId, { headers: authHeader() });
    }

    down(cameraId) {
        return axios.get(API_URL + "down/" + cameraId, { headers: authHeader() });
    }

    home(cameraId) {
        return axios.get(API_URL + "home/" + cameraId, { headers: authHeader() });
    }

    stop(cameraId) {
        return axios.get(API_URL + "stop/" + cameraId, { headers: authHeader() });
    }

    zoomIn(cameraId) {
        return axios.get(API_URL + "zoomIn/" + cameraId, { headers: authHeader() });
    }

    zoomOut(cameraId) {
        return axios.get(API_URL + "zoomOut/" + cameraId, { headers: authHeader() });
    }
}

export default new CameraService();