import React, { useState, useEffect } from 'react';
import Header from '../Helpers/Header';
import Button from '@mui/material/Button';
import Sidemenu from '../Helpers/Sidemenu';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import TextField from '@mui/material/TextField';
import { faPlus, faRemove, faCheck, faUpDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MeetingService from '../../../services/meeting-service';
import { useTranslation } from "react-i18next";

const SortableItem = SortableElement(({ key, value, handleDeleteClick, handleNameChange, handlePointActivation }) => <li>
    <div className="row">
        <div className="col-2 grab">
            <Button onClick={(e) => { handleDeleteClick(value.id); }} type="button" variant="contained" sx={{ mt: 1, mb: 0, ml: 1, mr: 0, height: 55, background: "#AA4A44", float: "right" }}>
                <FontAwesomeIcon icon={faRemove} />
            </Button>
            <Button onClick={(e) => { handlePointActivation(value.id); }} type="button" variant="contained" sx={{ mt: 1, mb: 0, ml: 0, mr: 0, height: 55, background: "#AA4A44", float: "right" }}>
                <FontAwesomeIcon icon={faCheck} />
            </Button>
            <div style={{ width: "55px", height: "55px", marginTop: "20px" }}>
                <FontAwesomeIcon icon={faUpDown} />
            </div>
        </div>
        <div className="col-10"> <TextField style={value.isActive === true ? { border: "5px solid green", borderRadius: "5px" } : {}} sx={{ mt: 1, width: '96%' }} id={value.id.toString()} type="text" defaultValue={value.name} onChange={(e) => handleNameChange(e)} /></div>
    </div>
</li>);

const SortableList = SortableContainer(({ items, handleDeleteClick, handleNameChange, handlePointActivation }) => {
    return (
        <ul>
            {items.map((value, index) => (
                <SortableItem key={value.id} index={index} value={value} handleDeleteClick={handleDeleteClick} handleNameChange={handleNameChange} handlePointActivation={handlePointActivation} />
            ))}
        </ul>
    )
});

const Points = () => {

    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [meeting, setMeeting] = useState({ name: "", points: [] });

    const getMeeting = async () => {
        let result = await MeetingService.getMeeting();

        if (result.status === 200) {
            setMeeting(result.data);
        }
    }

    const handlePointNameChange = (e) => {
        let arr = meeting.points;
        let point = arr.find(p => p.id == e.currentTarget.id);
        let index = arr.indexOf(point);
        point = arr.splice(index, 1)[0]
        point.name = e.currentTarget.value;
        arr.splice(index, 0, point);
        sendPointNameChangeRequest(arr, point);
    }

    const handleMeetingNameChange = (e) => {
        sendMeetingNameChangeRequest({ ...meeting, name: e.currentTarget.value });
    }

    const handlePointActivation = async (pId) => {
        let result = await MeetingService.activatePoint(pId);

        if (result.status === 200) {
            setMeeting({ ...meeting, points: result.data });
        } else {
            alert("bad request sendOrderRequest");
        }
    }

    const onSortEnd = ({ oldIndex, newIndex }) => {
        let arr = meeting.points;
        let point = arr.splice(oldIndex, 1)[0]
        arr.splice(newIndex, 0, point);

        sendOrderRequest(arr);
    };

    const handleDeleteClick = async (pId) => {
        let result = await MeetingService.deletePoint(pId);

        if (result.status === 200) {
            getMeeting();
        } else {
            alert("bad request handleDeleteClick");
        }

    }

    const sendPointNameChangeRequest = async (arr, point) => {
        let result = await MeetingService.updatePoint(point);

        if (result.status === 200) {
            setMeeting({ ...meeting, points: arr });
        } else {
            alert("bad request sendPointNameChangeRequest");
        }
    }

    const sendMeetingNameChangeRequest = async (currentMeeting) => {

        let result = await MeetingService.updateMeeting(currentMeeting);

        if (result.status === 200) {
            setMeeting(currentMeeting);
        } else {
            alert("bad request sendMeetingNameChangeRequest");
        }
    }

    const sendOrderRequest = async (arr) => {
        var orderedIds = [];
        for (var i = 0; i < arr.length; i++) {
            orderedIds.push(arr[i].id);
        }

        let result = await MeetingService.orderPoints(orderedIds);

        if (result.status === 200) {
            setMeeting({ ...meeting, points: arr });
        } else {
            alert("bad request sendOrderRequest");
        }
    }

    const addPointRequest = async () => {
        let point = { name: t("settings.newMeetingPoint") };

        let result = await MeetingService.addPoint(point);

        if (result.status === 200) {
            let arr = meeting.points;
            arr.push(result.data);

            console.log(arr);
            setMeeting({ ...meeting, points: arr });
        } else {
            alert("bad request sendOrderRequest");
        }
    }

    useEffect(() => {
        if (loading === true) {
            getMeeting();
            setLoading(false);
        }
    });

    return (
        <div>
            <Header />
            <div className="content">
                <div className="pnazwa">
                    <input type="text" defaultValue={meeting.name} onChange={(e) => handleMeetingNameChange(e)} size="30" />

                    <Button onClick={(e) => { addPointRequest(); }} type="button" variant="contained" sx={{ mt: 0, mb: 0, height: 60, background: "#AA4A44", position: "absolute", right: 45 }}>
                        <FontAwesomeIcon icon={faPlus} />
                    </Button>
                </div>
                <div className="boxes">
                    <SortableList items={meeting.points} handleDeleteClick={handleDeleteClick} handlePointActivation={handlePointActivation} handleNameChange={handlePointNameChange} onSortEnd={onSortEnd} axis="y" lockAxis="y" />
                </div>
            </div>
            <Sidemenu page="points" />
        </div>
    );
}
export default Points;