import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useParams } from "react-router-dom";
import AuthService from "./../services/auth-service";

const Remind = () => {

    const { token } = useParams();

    const Copyright = (props) => {
        return (
            <Typography variant="body2" color="text.secondary" align="center" {...props}>
                {`Copyright ${String.fromCodePoint(0x00A9)} `}
                <Link color="inherit" href="https://esitzung.pl/">
                    esitzung.pl
                </Link>{' '}
                {new Date().getFullYear()}
                {'.'}
            </Typography>
        );
    }

    const defaultTheme = createTheme();

    const handleSubmit = async (event) => {
        event.preventDefault();

        const data = new FormData(event.currentTarget);

        if (data.get('newPassword') === data.get('repeat')) {
            let result = await AuthService.remindConfirmation(data.get('newPassword'), data.get('repeat'), token);
            if (result.status === 200) {
                alert('Your password is changed successfully.');
            } else {
                alert('An error occured during password change: ' + result.data);
            }
        } else {
            alert('Passwords have to be the same.');
        }
    };


    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Set new password
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="newPassword"
                            label="New password"
                            name="newPassword"
                            autoComplete="password"
                            type="password"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="repeat"
                            label="Repeat password"
                            name="repeat"
                            autoComplete="repeat"
                            type="password"
                        />
                        <Button
                            style={{ background: "#AA4A44" }}
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Zapisz
                        </Button>

                        <Grid container className="mt-4">
                            <Grid item xs>
                                <Link href="/login" variant="body2">
                                    {"Already have an account?"}
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="/register" variant="body2">
                                    {"Don't have an account?"}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </ThemeProvider>

    );

}

export default Remind;