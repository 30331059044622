import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import CameraService from "../../../services/camera-service";
import { EditCameraModal } from '../../Helpers/EditCameraModal';
import Header from '../Helpers/Header';
import Sidemenu from '../Helpers/Sidemenu';

const Cameras = () => {

    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [cameras, setCameras] = useState([]);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [selectedCameraId, setSelectedCameraId] = useState('');



    const getCameras = async () => {
        let result = await CameraService.getall();

        if (result.status === 200) {
            setCameras(result.data);
            setLoading(false);
        }
    }

    const handleEditClick = (cameraId) => {
        setEditModalIsOpen(true);
        setSelectedCameraId(cameraId);
    }

    const handleDeleteClick = (cameraId) => {
        setDeleteModalIsOpen(true);
        setSelectedCameraId(cameraId);
    }

    const deleteCameraConfirmation = () => {
        if (cameras.length > 1) {
            console.log("delete camera" + selectedCameraId);
        } else {
            console.log("nie można usunąć kamery" + selectedCameraId);
        }
        setDeleteModalIsOpen(false);
        setSelectedCameraId('');
    }

    const onDeleteClose = () => {
        setDeleteModalIsOpen(false);
        setSelectedCameraId('');
    }

    const onEditClose = () => {
        setEditModalIsOpen(false);
        setSelectedCameraId('');
    }

    const onEditCloseConfirmation = () => {
        setEditModalIsOpen(false);
        setSelectedCameraId('');
        getCameras();
    }

    useEffect(() => {
        if (loading) { getCameras(); }
    });

    return (
        <div>
            <EditCameraModal modalIsOpen={editModalIsOpen} onConfirmation={onEditCloseConfirmation} onClose={onEditClose} itemId={selectedCameraId}
                title={t("camera.edit.title")} newPresetName={t("camera.edit.newPresetName")} addButtonText={t("button.add")} confirmationButtonText={t("button.save")}
                nameText={t("camera.edit.name")} ipAddressText={t("camera.edit.ipAddress")} portText={t("camera.edit.port")} endpointText={t("camera.edit.endpoint")} userNameText={t("camera.edit.userName")} passwordText={t("camera.edit.password")}
                presetsTitle={t("camera.edit.presets.title")} presetsName={t("camera.edit.presets.name")} presetsDeleteTitle={t("camera.edit.presets.delete.title")} presetsDeleteText={t("camera.edit.presets.delete.text")} presetsDeleteButtonYes={t("button.yes")} presetsDeleteButtonNo={t("button.no")}
                rejectButtonText={t("button.cancel")} width={'80%'} height={'650px'} />

            <Header />
            <div className="content">
                <div className="pnazwa">
                    <input type="text" disabled defaultValue={t("camera.title")} size="30" />
                </div>
                <div className="boxes">
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col-2">#</th>
                                <th scope="col-3">{t("camera.title")}</th>
                                <th scope="col-2">{t("camera.ipAddress")}</th>
                                <th scope="col-1">{t("camera.port")}</th>
                                {/*<th scope="col-1">{t("camera.endpoint")}</th>*/}
                                <th scope="col-2">{t("camera.userName")}</th>
                                <th scope="col-2">{t("camera.password")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {cameras?.map((camera, key) => {
                                return <tr key={key}>
                                    <td className="col-2">
                                        <div className="row">
                                            <div className="col-8">
                                                <Button onClick={(e) => { handleEditClick(camera.id); }} type="button" variant="contained" sx={{ mt: 0, mb: 0, mr: 1, height: 35, background: "#AA4A44" }}>
                                                    <FontAwesomeIcon icon={faEdit} />
                                                </Button>
                                            </div>

                                        </div>
                                    </td>
                                    <td className="col-3">{camera.name}</td>
                                    <td className="col-2">{camera.ipAddress}</td>
                                    <td className="col-1">{camera.port}</td>
                                    {/*<td className="col-1">{camera.endpoint}</td>*/}
                                    <td className="col-2">{camera.login}</td>
                                    <td className="col-2">{camera.password}</td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            <Sidemenu page="cameras" />
        </div>
    );
}

export default Cameras;