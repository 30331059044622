import { faMicrophone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as signalR from "@microsoft/signalr";
import React, { useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import { useParams } from "react-router-dom";
import StreamService from '../../services/stream-service';
import { useTranslation } from "react-i18next";

const Streamer = () => {

    const { t } = useTranslation();

    const [loading, setLoading] = useState(true);
    const [stream, setStream] = useState({ url: "", meeting: { pauseTo: null, name: "" }, board: null, speaker: null, client: { base64String: null } });
    const [boardHtml, setBoardHtml] = useState(null);
    const [speakerHtml, setSpeakerHtml] = useState(null);

    let { token } = useParams();

    const getStream = async () => {
        let result = await StreamService.get(token);
        if (result.status === 200) {
            setStream(result.data);
            if (result.data.pauseTo == null) {
                setBoardHtml(showBoard(result.data.board));
                setSpeakerHtml(showSpeaker(result.data.speaker));
                signalrConnect(result.data.client.id)
            }
        }
    }

    const getPopup = (innerHtml) => {
        return <div className="streamer-popup"><table style={{ height: "100%", width: "100%" }} ><tbody><tr><td valign="center" align="center">{innerHtml}</td></tr></tbody></table></div>;
    }

    const showBoard = (board) => {
        if (board === null) return null;

        console.log(board, board.base64)

        return getPopup(<img className="img-wrap" src={board.url != null ? board.url : "data:image/png;base64," + board.base64String} alt="board" />);
    }

    const showSpeaker = (speaker) => {
        if (speaker === null) return null;
        return <div className="speaker"> <FontAwesomeIcon icon={faMicrophone} />  {speaker.firstName} {speaker.lastName}<span>{speaker.position}</span></div>;
    }

    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            return null;
        } else {
            return getPopup(<div><h1>{t("breakInMeeting")}</h1><h2>{hours < 10 ? "0" + hours : hours}:{minutes < 10 ? "0" + minutes : minutes}:{seconds < 10 ? "0" + seconds : seconds}</h2></div>);
        }
    };

    useEffect(() => {
        if (loading) {
            getStream();
            setLoading(false);
        }
    });

    const signalrConnect = (clientId) => {
        const connect = new signalR.HubConnectionBuilder()
            .withUrl("/hub/stream", {
                //skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets,
                accessTokenFactory: () => {
                    return token
                }
            })
            .withAutomaticReconnect()
            .build();

        connect.start()
            .then(async () => {
                await connect?.invoke("JoinGroup", clientId.toString())
            }).catch((error) => { console.log("connection error:", error); });

        connect.on("RefreshBoard", (data) => {
            setBoardHtml(showBoard(data));
        });

        connect.on("RefreshMeeting", (data) => {
            setStream(stream => ({ ...stream, meeting: data }));
        });

        connect.on("RefreshPoints", (data) => {
            setStream(stream => ({ ...stream, meeting: { ...stream.meeting, points: data } }));
        });

        connect.on("RefreshSpeaker", (data) => {
            setSpeakerHtml(showSpeaker(data));
        });
    }

    return (
        <div style={{ width: "100%", padding: "0px" }}>
            <header>
                <div style={{ display: "inline-block", width: "15%", height: "100%", padding: "5px" }}>
                    <img style={{ height: 60, display: "block", float: "left" }} src="./logo.png" alt="logo" />
                </div>
                <div style={{ display: "inline-block", width: "85%", height: "100%", backgroundColor: "#ededed", color: "#627488", padding: "5px" }}>
                    <h2 style={{ float: "left", fontSize: "25px", padding: "10px" }}>{stream?.meeting?.name}</h2>
                    <img style={{ height: 60, display: "block", float: "right" }} src={stream.client.base64String == null ? "https://placehold.co/600x400" : ("data:image/png;base64," + stream.client.base64String)} alt="logo" />
                </div>
            </header>

            <Countdown key={stream.meeting.pauseTo} date={stream.meeting.pauseTo} renderer={renderer} />
            {boardHtml}
            {speakerHtml}

            {stream?.meeting?.points?.map((point, key) => {
                return <div key={key} className={point.isActive ? "streamer-point" : "streamer-point-hide"}>{point.name}</div>;
            })}

        </div>
    );
}
export default Streamer;